import React from "react";
import Follow from "../footer/Follow";
import EntertainMain from "./entertainComp/EntertainMain";
import EntSection from "./entertainComp/EntSection";
import Footer from "../footer/Footer";
import NewNavbar from "../navbar/Navbar";

function Entertain() {
    return(
        <>
            <EntertainMain />
            <EntSection />
            <Follow />
            <Footer />
        </>
    )


}

export default Entertain;