import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "./EventsMain.css"
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";


const ContainerCust = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
  
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-0`;

function EventsMain() {
    return(


        <div className="main--cont--events">
            <OpacityOverlay />
            <Container >
                <Fade bottom>
                <div className="events--main--title"><div className="textev">EVENTS</div></div>
                </Fade>
            </Container>
            
        </div>
    )
}


export default EventsMain;