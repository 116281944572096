import React from "react";
import homebg from "../../assets/video/homebg.mp4";
import "./HomeMain.css";
import csclogo from "../../assets/images/csclogo.png";
import tw from "twin.macro";




const Column = tw.div``;
const ImageColumn = tw(Column)`  relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image1 = tw.img`max-w-full w-96 sm:rounded relative p-4`;

function HomeMain() {
    return(
        <div className="video-container">
            <video autoPlay loop muted className="home--bg--video">
                <source src={homebg} type="video/mp4"/>
            </video>
            <div className="overlay">
            </div>
            <div className="logo--overlay">
            <ImageColumn>
                    <ImageContainer>
                        <Image1 src={csclogo} />
                    </ImageContainer>
                </ImageColumn>
            </div>
                
            
        </div>
    )
}


export default HomeMain;