import React from 'react';
import { Container } from "react-bootstrap";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components";
import { Fade } from "react-reveal";
import CountUp from 'react-countup';
import './AboutUs.css'
import SomeImg from '../assets/images/about_pic.png'
import Follow from '../footer/Follow'
import Footer from '../footer/Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Bearers from './Bearers';
import {Link} from "react-router-dom";



const Post = tw.a `flex flex-col h-full bg-white rounded`;
const PostImage = styled.div `
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}  
`;
const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.p`transition duration-300 `;
const PostContainer = styled.div`
  ${tw`relative z-20 mt-0 sm:pt-3 pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw``}
    }
 
    
  `}
`;

const ContainerCust = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
  
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-0`;
const SectionHeading = tw.h2` pt-4   tracking-wide text-center`
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)` lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl justify-center`;
const Heading = tw(SectionHeading)`xl:text-center lg:text-center sm:text-center leading-snug  mt-2 mb-10`;
const ImageColumn = tw(Column)` lg:mr-0 relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image1 = tw.img`max-w-full w-96 sm:rounded relative p-4`;

const AboutUs = () => {
    
  const lazyElement = <LazyLoadImage alt="About Us Image" height={400} src={SomeImg} width={400} />
  
  return(
        <div> 
                 <div className="main--cont--about">
            <OpacityOverlay />
            <Container >
                <Fade bottom>
                <div className="about--main--title"><div className="textab">ABOUT US</div></div>
                </Fade>
            </Container>
        </div>
        <Container>
        <div style={{ padding:50 }} >
                    <Fade bottom>
                    <p className='about-para-csc' style={{textAlign:"justify", textAlignLast:"center"}} >
                    <b style={{color:"#26873e"}}>The	coimbatore	social	club</b>	was	established	in	year	1955	(5th	June	1955)	enthused	by	values	of	noble	ethics	
                      and	bountiful	happiness. It	is	one	of	the	Coimbatore’s	premier	social	club,	has
                      member	strength	of	750	and	more.	Representing	from	various	backgrounds	with	a	unique	spirit	and	values	
                      of	the	city	.	The	club	is	located	amid	5	kms	from	Railway	station	,	Gandhipuram	,	Ukkadam	bus	stand	and	15	
                      kms	from	Airport.<br /><br />	
                      <div>
                      <Fade bottom>
                        <p  style={{color: "#e5cb5e", textAlign:"center"}} className='club--best'>
                          <b>
                          OUR	CLUB	IS		ONE	OF	THE	FINEST	CLUB	IN	COIMBATORE,	BEING	LOCATED	CENTRALLY	
                          </b>
                        </p>
                      </Fade>
                        <Fade bottom>
                      <div style={{marginTop:70}} className="main--title--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">Founders of our club</span></p></div>
                      </Fade>
                      <div className='founders'>
                        <ol>
                        <Fade bottom>
                          <li>
                            Mr. A.N NAYAK 
                          </li>
                          </Fade>
                          <Fade bottom>
                          <li>
                            Dr. J.G SHANMUGANATHAN MBBS DA (London)
                          </li>
                          </Fade>
                          <Fade bottom>
                          <li>
                            Mr. JAYSI PREMJI 
                          </li>
                          </Fade>
                          <Fade bottom>
                          <li>
                            MR. V SATHYAMOORTHY 
                          </li>
                          </Fade>
                        </ol>
                        </div>
                      </div>
                      

                    </p>
                    </Fade>
          </div>
          
          {/* <div>
            <Fade bottom>
            <div style={{marginTop:70}} className="main--title--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">No. Of trustable members</span></p></div>
              </Fade>
              </div>
            <div
            className='no-of-members'
            >
            <CountUp end={700} />

            </div>
            <Fade bottom>
            <div className="about-follow--main--title">and still counting
          </div>
          </Fade> */}
                    <Bearers />

          <Fade bottom>
          <div className="main--facility--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">FACILITIES AVAILABLE</span></p></div>
          </Fade>
                  <div
          className="fac-list-box">
          <ul>
            <Fade bottom>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Indoor badminton court with a synthetic floor
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Ample	parking	space	
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Air	conditioned	conference	hall
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Fully	air	conditioned	domicile	with	5	Deluxe	suite	&	4	Double	rooms
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                  Table Tennis
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Full-fledged Gym
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                A/C Cards hall

                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Reading Room
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Zero Degree AC Bar
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Lawn service
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Canteen Facility
                </p>
                </li>
                </Fade>
          </ul>
                  </div>
                  
                 
                  <div
                  style={{
                    padding:50
                  }}
                  >
                    <Fade bottom>
                    <p className='about-para-csc' style={{textAlign:"justify", textAlignLast:"center"}}>
                    <b style={{color:"#26873e"}}>The Coimbatore Social Club</b> also has <a href="/pvtroom" style={{color:"#e5cb5e"}}>
                    9 AC double rooms</a> to accommodate members of affiliated clubs and guests of our members. We have a car parking facility.
          <br/> <br/> At <b style={{color:"#26873e"}}>The Coimbatore Social Club</b>  we believe regular exercise has a direct bearing on fitness and health. It also becomes an important element in our lives. Our <a href="/gym" style={{color:"#e5cb5e"}}>Gym equipment</a> is really amazing, and the place is well maintained with good motivation for music. The Coimbatore Social Club offers a variety of options for its members to indulge in indoor sports activities, with the highest-quality. We have a pleasing <a href="/shuttle" style={{color:"#e5cb5e"}}>indoor badminton court</a> with a synthetic floor.
          The Coimbatore Social Club offers a high-energy, contemporary <a href="/fandd" style={{color:"#e5cb5e"}}>bar</a> serving unique cocktails with liquid crystal house music adding to the ambience.<br/><br/> Our club members believe their network has grown magnificently, The Coimbatore Social Club has given them the space to find the right people, who will help, support, and guide them. <i>“friend in need is a friend indeed”</i> – Our Club members develop a great level of bonding for one another which helps them gain trust and support to grow financially and as a good person.
<b style={{color:"#26873e"}}>The Coimbatore Social Club</b> conducts various events to encourage club members to participate and have fun. Just check this <a href='/events' style={{color:"#e5cb5e"}}>The Events</a> what our club members think about the club and their fellow members. 
                    </p>
                    </Fade>
          </div>
          <Container style={{padding:"3rem 0"}}>
            <Fade bottom>
          <div  className="main--title--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">PLACE OF INTEREST</span></p></div>
          </Fade>
          <Row className="row--ent">
          <ImageColumn  className="res--bottom--ent">
              <ImageContainer>
                <Image1 src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBIIRvxdgyCMXVsBrmn7liRTRLW2U4tz_gtA&usqp=CAU'  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">MARUTHAMALAI</span></p></Heading>
              <div>
                    <p className="main--para--carrom">Marudhachalamurthy Temple, Marudamalai is a popular 12th-century hill temple situated in Coimbatore, Tamil
Nadu, India. Built by Tamil kings during the Sangam period as indicated in the Purananuru, the temple is
dedicated to Lord Murugan and is considered the Seventh House of Lord Murugan.</p>
              </div>
              </Fade>
            </TextColumn>
          </Row>
          <Row className="row--ent">
            <TextColumn>
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">PERUR</span></p></Heading>
              <div>
                    <p className="main--para--carrom">Perur Pateeswarar Temple is a Hindu temple dedicated to Shiva located at Perur, in western part
of Coimbatore in state of Tamil Nadu in India. The temple was built by Karikala chola in 2nd Century. The temple
is located on the bank of the Noyyal River.</p>
              </div>
              </Fade>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image1 src='http://img1.dinamalar.com/Kovilimages/T_500_460.jpg' style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
          </Row>
          <Row className="row--ent">
          <ImageColumn className="res--bottom--ent">
              <ImageContainer>
                <Image1 src='https://pbs.twimg.com/media/E4pP2UpUYAAyij4?format=jpg&name=large'  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">DHYANALINGA</span></p></Heading>
              <div>
                    <p className="main--para--carrom">The Dhyanalinga is set amidst the foothills of the Velliangiri Mountains, the first of its kind to be completed in
over 2000 years. This powerful meditative space does not ascribe to any particular faith or belief system nor
does it require any ritual, prayer, or worship.</p>
              </div>
              </Fade>
            </TextColumn>
          </Row>
          <Row className="row--ent">
            <TextColumn>
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">OOTY</span></p></Heading>
              <div>
                    <p className="main--para--carrom">	Ooty (short for Udhagamandalam) is a resort town in the Western Ghats mountains, in southern India's Tamil
Nadu state. Founded as a British Raj summer resort, it retains a working steam railway line. Other reminders of
its colonial past include Stone House</p>
              </div>
              
              </Fade>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image1 src="https://images.unsplash.com/photo-1559147861-32715680aef8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format"  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
          </Row>
          <Row className="row--ent">
            
            <ImageColumn className="res--bottom--ent">
              <ImageContainer>
                <Image1 src='https://images.unsplash.com/photo-1508817628294-5a453fa0b8fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format'  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">MUDUMALAI</span></p></Heading>
              <div>
                    <p className="main--para--carrom">Mudumalai National Park is a national park in the Nilgiri Mountains in Tamil Nadu, south India. It covers
321 km2 (124 sq mi) at an elevation range of 850–1,250 m (2,790–4,100 ft) in the Nilgiri Districtand shares
boundaries with the states of Karnataka and Kerala.</p>
              </div>
              
              </Fade>
            </TextColumn>
          </Row>
          <Row className="row--ent">
          <TextColumn>
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">KODAIKANAL</span></p></Heading>
              <div>
                    <p className="main--para--carrom">Kodaikanal is a hill station which is located in Dindigul district in the state of Tamil Nadu,
India. Its name in the Tamil language means "The Gift of the Forest". Kodaikanal is referred to as the "Princess
of Hill stations" and has a long history as a retreat and tourist destination.</p>
              </div>
              
              </Fade>
            </TextColumn>
            <ImageColumn >
              <ImageContainer>
                <Image1  src='https://images.unsplash.com/photo-1595500828636-fbd6bf24517f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format' style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            
          </Row>
          </Container>
          </Container>
        <Follow/>
        <Footer/>
        </div>
    )
}


export default AboutUs;