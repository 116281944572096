import React, {useState, useEffect} from "react";
import "./Testimonial.css";
import { Container, Carousel } from "react-bootstrap";
import { Fade, LightSpeed, Zoom } from "react-reveal";
import firebase from "firebase";
import tw from "twin.macro";


const SectionHeading = tw.h2` pt-4   tracking-wide text-center`
const Column = tw.div``;
const ImageColumn = tw(Column)` lg:mr-0 relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Heading = tw(SectionHeading)`xl:text-center lg:text-center sm:text-center leading-snug  mt-2 mb-10`;

function Testimonial() {
    const [index, setIndex] = useState(0);
     const [testimonialData, settestimonialData] = useState([]);


  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  const getAllTestimonial = () => {
      var myRef = firebase.database().ref(`/testinomials`);
      myRef.on('value', snap => {
          if(snap.val()){
              settestimonialData(snap.val())
          }
      })
  }

  useEffect(() => {
      getAllTestimonial()
  }, []);
  


    return(
        <div className="main--cont--testim">
            
        <Container >
            <div>
                <Fade bottom>
        <div className="main--sub--testim"><p className="crossline--testim"><span className="tsspan">TESTIMONIAL</span></p></div>
        </Fade>
        </div>
            <div className="cont--in--testim">
                <div className="cont--in--homesectcont">
                
                <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} prevIcon={false} nextIcon={false} interval={4000}>
                    
                    {testimonialData && Object.entries(testimonialData).map((item, index) => {
                        return(
                            <Carousel.Item > 
                             <div className="main--para--testim">
                                {item[1].testimonial}
                                        </div>
                                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                        <ImageColumn><ImageContainer><img style={{width:"80px", height:"80px", borderRadius:"50%", marginLeft:"0.5rem"}} src={item[1].profile} /></ImageContainer></ImageColumn>
                                    <div className="main--name--testim">{item[1].name}</div>
                                </div>                
                        </Carousel.Item>
                        )
                    })
                    }
                    </Carousel>
                </div>
            </div>
        </Container>
    </div>
    )
}

export default Testimonial;