import React from "react";
import BgAsImage from "./Foodanddrinkcomp/BgAsImage";
import FanddSection from "./Foodanddrinkcomp/FanddSection";
import FoodAndDrinksMain from "./Foodanddrinkcomp/FoodAndDrinkMain";
import Follow from "../footer/Follow";
import Footer from "../footer/Footer";
import Zero from "./Foodanddrinkcomp/Zero";


function FoodAndDrinks() {
    return(
        <>
        <FoodAndDrinksMain />
        <FanddSection />
        <Zero />
        <Follow />
        <Footer />
        </>
    )
}

export default FoodAndDrinks;