import React, { useState, useEffect } from 'react';
import Follow from "../footer/Follow";
import Footer from "../footer/Footer";
import firebase from 'firebase/app';
import 'firebase/database';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Box, TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

function Affiliation() {
  const [allAffiliationsData, setAllAffiliationsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAffiliationsData, setFilteredAffiliationsData] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [states, setStates] = useState([]);

  useEffect(() => {
    const myRef = firebase.database().ref('/affiliations');
    myRef.on('value', (snap) => {
      if (snap.val()) {
        const data = Object.entries(snap.val());
        setAllAffiliationsData(data);
        setFilteredAffiliationsData(data);

        // Extract unique states from the data
        const uniqueStates = [...new Set(data.map(([id, affiliation]) => affiliation.state || 'Unknown'))];
        setStates(uniqueStates);
      }
    });
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    filterData(searchTerm, selectedState);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    filterData(searchTerm, event.target.value);
  };

  const filterData = (searchTerm, state) => {
    const filteredData = allAffiliationsData.filter(([id, data]) => {
      const matchesSearch = data.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesState = state === '' || data.state === state;
      return matchesSearch && matchesState;
    });
    setFilteredAffiliationsData(filteredData);
  };

  const pageStyles = {
    margin: '80px auto 30px auto',
    padding: '40px 100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  };

  const isMobile = window.innerWidth <= 768;
  if (isMobile) {
    pageStyles.padding = '20px';
  }

  const tableWrapperStyles = {
    width: '100%',
    overflowX: 'auto',
    marginBottom: '20px',
  };

  const tableStyles = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    background: '#f9f9f9',
    width: '100%',
  };

  const cellStyles = {
    borderRight: '1px solid #ddd',
    padding: '10px 15px',
  };

  const headerCellStyles = {
    ...cellStyles,
    borderRight: '1px solid #fff',
    color: '#fff',
    fontWeight: 'bold',
  };

  const lastCellStyles = {
    borderRight: 'none',
  };

  return (
    <div>
      <div style={pageStyles}>
        <Box textAlign="center" marginBottom="20px">
          <Typography variant="h4" style={{ fontWeight: 'bold', color: '#14642d' }}>
            Affiliation Details
          </Typography>
        </Box>

        {/* Search Bar and State Filter */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          <TextField
            label="Search Affiliation Name"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: { xs: '100%', sm: '300px' } }}
          />
          <FormControl sx={{ minWidth: { xs: '100%', sm: '200px' } }}>
            <InputLabel>State</InputLabel>
            <Select value={selectedState} onChange={handleStateChange} label="State">
              <MenuItem value="">All States</MenuItem>
              {states.map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              backgroundColor: '#14642d',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#0e4c20',
              },
            }}
          >
            Search
          </Button>
        </Box>

        {filteredAffiliationsData.length > 0 ? (
          <div style={tableWrapperStyles}>
            <Table style={tableStyles}>
              <TableHead>
                <TableRow style={{ background: '#14642d' }}>
                  <TableCell style={{ ...headerCellStyles, minWidth: '50px' }}>S. No.</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '150px' }}>Name</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '200px' }}>Address</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '150px' }}>State</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '150px' }}>Phone Number</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '150px' }}>Fax</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '200px' }}>Email</TableCell>
                  <TableCell style={{ ...headerCellStyles, minWidth: '200px', ...lastCellStyles }}>Website</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAffiliationsData.map(([id, data], index) => (
                  <TableRow key={id} style={{ borderBottom: '1px solid #ddd' }}>
                    <TableCell style={{ ...cellStyles, minWidth: '50px' }}>{index + 1}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '150px' }}>{data.name || '-'}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '200px' }}>{data.address || '-'}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '150px' }}>{data.state || 'Unknown'}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '150px' }}>{data.phoneNumber || '-'}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '150px' }}>{data.fax || '-'}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '200px' }}>{data.email || '-'}</TableCell>
                    <TableCell style={{ ...cellStyles, minWidth: '200px', ...lastCellStyles }}>{data.website || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: 'center', marginTop: '20px', color: '#6c757d', fontStyle: 'italic' }}
          >
            No affiliation data available to display.
          </Typography>
        )}
      </div>
      <Follow />
      <Footer />
    </div>
  );
}

export default Affiliation;
