import React from 'react';
import {Router , Redirect, Route } from 'react-router-dom';
import { isLogin } from './admin_helper';

const AdminRoute = ({component: Component, ...rest}) => {
    return(
        
        <Route
        {...rest} render={props => (
            isLogin() ?(
                <Component {...props} />
                )
            : (
            <Redirect
            to={{
                pathname: "/login",
                state: { from: props.location }
            }}
                />
        
        )
        )}
            
        />
    )
}


export default AdminRoute;