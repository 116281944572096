import React from "react";
import { Container } from "react-bootstrap";
import "./Society.css";
import tw from "twin.macro";
import { Bounce } from "react-reveal";

const SectionHeading = tw.h2` pt-4   tracking-wide text-center`
const Row = tw.div`flex flex-col lg:flex-row items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8 `;
const Column = tw.div``;
const TextColumn = tw(Column)` lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl justify-center bg-white h-32 sm:h-44 flex justify-center text-center`;

const ImageColumn = tw(Column)` lg:mr-0 relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image1 = tw.img`max-w-full w-96 sm:rounded relative p-4`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;

function Society() {
    return(
        <div>
            <div className="society--heading">
            We have Members from a cross-section of the society.
            </div>
        <div className="main--cont--society">
         
            <Container>
                <Row style={{justifyContent:"space-evenly"}}>
                    
                    <TextColumn className="society--textcol">
                    <Bounce bottom>
                        <div className="society--card--name">Business Community, Bank and Insurance officials</div>
                        </Bounce>
                    </TextColumn>
                    <TextColumn className="society--textcol">
                    <Bounce bottom>
                        <div className="society--card--name">Serving State / Central Government officials</div>
                        </Bounce>
                    </TextColumn>
                </Row>
                <Row style={{justifyContent:"space-evenly"}}>
                    
                    <TextColumn className="society--textcol">
                    <Bounce bottom>
                        <div className="society--card--name">Retired Government officials and Employees</div>
                        </Bounce>
                    </TextColumn>
                    <TextColumn className="society--textcol">
                    <Bounce bottom>
                        <div className="society--card--name"><b>Professionals </b> <br/> Doctors, Engineers, Advocates and Auditor</div>
                        </Bounce>
                    </TextColumn>
                </Row>
            </Container>
        </div>
        </div>
    )
}

export default Society;