import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/system';
import "./Admin.css";
import firebase from 'firebase';
import { Container, Spinner, Row, Col, Form  } from 'react-bootstrap';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import 'firebase/database'
import 'firebase/storage'

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/material/Box'; 
import { Button, CardActionArea, CardActions, Box } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { logout } from '../PrivateRoutes/admin_helper';
import { Redirect } from 'react-router-dom';


    const blue = {
        50: '#F0F7FF',
        100: '#C2E0FF',
        200: '#80BFFF',
        300: '#66B2FF',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        700: '#0059B2',
        800: '#004C99',
        900: '#003A75',
    };
    
    // Styling for individual Tab
    const StyledTab = styled(Tab)(() => ({
        fontFamily: 'IBM Plex Sans, sans-serif',
        color: 'white',
        cursor: 'pointer',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        width: '100%',
        padding: '12px 16px',
        margin: '6px 6px',
        border: 'none',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: blue[400],
        },
        '&.Mui-selected': {
            backgroundColor: blue[50],
            color: blue[600],
        },
        '&.Mui-disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
        },
    }));

    // Styling for TabPanel using Box as container
    const StyledTabPanel = ({ value, index, children }) => {
        return (
            <div role="tabpanel" hidden={value !== index} style={{
                width: '100%',
                fontFamily: 'IBM Plex Sans, sans-serif',
                fontSize: '0.875rem',
            }}>
                {value === index && <Box p={2}>{children}</Box>}
            </div>
        );
    };

    // Styling for the Tabs container
    const StyledTabsList = styled(Tabs)(() => ({
        minWidth: '320px',
        backgroundColor: blue[500],
        borderRadius: '8px',
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'space-between',
        color: 'white'
    }));

    const firebaseConfig = {
      apiKey: "AIzaSyBvkrVdi-2xEPmOM_UMgZ2Z8S50FBgt87A",
      authDomain: "csc-final-8dfe3.firebaseapp.com",
      databaseURL: "https://csc-final-8dfe3-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "csc-final-8dfe3",
      storageBucket: "csc-final-8dfe3.appspot.com",
      messagingSenderId: "711428369722",
      appId: "1:711428369722:web:986f0e62e78cf5537cb403",
      measurementId: "G-ZFRKLT5V7G"
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }else {
      firebase.app(); // if already initialized, use that one
    }

const Admin = () => {
    //selected tab
    const [selectedTab, setSelectedTab] = useState(0);

    const [loadingStatus, setloadingStatus] = useState(false);
    const [progressing, setprogressing] = useState(false);
    const [adminImage, setadminImage] = useState('');
    const [adminImageUrl, setadminImageUrl] = useState('');
    const [imageCategory, setimageCategory] = useState('All');
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);
    const [imageUrls, setimageUrls] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    // Test
    const [testimonyTest, settestimonyTest] = useState('');
    const [testimonyGiverName, settestimonyGiverName] = useState('');
    const [testimonyGiverPicurl, settestimonyGiverPicurl] = useState('');
    const [testimonyGiverOccupation, settestimonyGiverOccupation] = useState('');

    const handleClick = (id) => ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id);

        setSelected((currentSelected) =>
            itemSelected
            ? currentSelected.filter((el) => el !== id)
            : currentSelected.concat(id)
        );
    };

    useEffect(() => {
        if(success)
            setTimeout(() => {
                setsuccess(false)
            }, 2000);
    }, [success]);

    useEffect(() => {
        if(imageCategory != 'All'){
            firebase.database().ref(`/images`).orderByChild('category').equalTo(imageCategory).on('value' , snap => {
                if(snap.val()){
                    console.log(snap.val());
                    setimageUrls(snap.val())
                }
            })
        }else{
            let myRef =  firebase.database().ref(`/images`).on('value' , snap => {
                if(snap.val()){
                    setimageUrls(snap.val())
                }
            })
        }  
    }, [imageCategory]);

    const successMesg = () => {
        if(success)
        return(
            <Alert severity="success">
    <AlertTitle>Success</AlertTitle>
    Image is uploaded successfully
    </Alert>
        )
    }

    const adminImageHandle = (e) =>{
        setadminImage(e.target.files[0])
    }

    const [adminTestImage, setadminTestImage] = useState('');
    const [adminTestImageURL, setadminTestImageURL] = useState('');
    const [adminImageProgress, setadminImageProgress] = useState(false);
    const [adminTestSuccess, setadminTestSuccess] = useState(false);
    const [adminTestError, setadminTestError] = useState(false);
    const [allTestimonialData, setallTestimonialData] = useState([]);

    useEffect(() => {
        firebase.database().ref(`/testinomials`).on('value' , snap => {
            if(snap.val()){
                setallTestimonialData(snap.val())
            }
        })
    }, [adminTestSuccess]);
    
    const adminTestimonyImageHandle = (e) => {
        setadminTestImage(e.target.files[0])
    }

    const adminTestimonyUpload = (e) => {
        e.preventDefault();
        let file = adminTestImage;
        var storage = firebase.storage();
        var storageRef = storage.ref();
        var uploadTask;

        uploadTask = storageRef.child(`/testimonial/image/${uuidv4()}/${file.name}`).put(file);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) =>{
                var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
                setprogressing(true)
            },(error) =>{
                throw error
            },
            () =>{
            uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
                setadminTestImageURL(url)
                settestimonyGiverPicurl(url)
                setprogressing(false)
                setsuccess(true)
            })
        }) 
    }

    const submitTestimonial = (e) => {
        e.preventDefault();
        var ID = uuidv4();

        if(testimonyGiverName, testimonyGiverOccupation,  testimonyTest) {
            var myRef = firebase.database().ref(`/testinomials/${ID}`);
            myRef.set({
                name: testimonyGiverName,
                profile: testimonyGiverPicurl,
                occupation: testimonyGiverOccupation,
                testimonial: testimonyTest
            })
            .then(() => {
    
                setadminTestSuccess(true)
                setadminTestImage('')
                setadminImageProgress(false)
                settestimonyTest('')
                settestimonyGiverName('')
                settestimonyGiverPicurl('')
                settestimonyGiverOccupation('')
            })
            .catch(err => {
                setadminTestError(true)
            })
        }        
    }

    const adminUpload = (e, section) => {
        e.preventDefault();
        var selected_section = section;
        let file = adminImage;

        var storage = firebase.storage();
        var storageRef = storage.ref();
        var uploadTask;

        if(selected_section)
        uploadTask = storageRef.child(`${selected_section}/image/${uuidv4()}/${file.name}`).put(file);
    
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) =>{
                var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
                setprogressing(true)
            },
            (error) =>{
                throw error
            },
            () =>{
            uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
                setadminImageUrl(url)
                //save the category with url
                let id = uuidv4();
                if(section){
                    let myRef =  firebase.database().ref(`/images/${id}`);
                    myRef.set({
                        url :url,
                        category: section
                    })
                    .then(res => {
                        console.log(res)
                        setsuccess(true)
                    })
                    .catch(err => {
                        console.log(err)
            
                        seterror(true)
                    })
                }  
            setprogressing(false)
            setloadingStatus(true)
            })
        }) 
    }

    const deleteTestimonial = (e,id) => {
        e.preventDefault();
        let myRef = firebase.database().ref(`/testinomials/${id}`)
        myRef.remove();
        setadminTestSuccess(true)
    }

    const deleteAdminPicures = (e,id) => {
        e.preventDefault();
        let myRef = firebase.database().ref(`/images/${id}`)
        myRef.remove();
        setsuccess(true)
    }

    // event and news
    const [eventtitleMsg, seteventtitleMsg] = useState('');
    const [eventDate, seteventDate] = useState(new Date());
    const [eventEndDate, seteventEndDate] = useState(new Date());
    const [descriptionEvent, setdescriptionEvent] = useState('');
    const [eventPicURL, seteventPicURL] = useState('');
    const [eventPicture, seteventPicture] = useState('');

    const [allEventsData, setallEventsData] = useState([]);

    const [eventSuccess, seteventSuccess] = useState(false);
    const [eventError, seteventError] = useState(false);
    const [eventFieldError, seteventFieldError] = useState(false);

    const [eventProgress, seteventProgress] = useState(false);

    const getEventData = () => {
        var myRef = firebase.database().ref(`/events`);
        myRef.on('value' , snap => {
            if(snap.val())
                setallEventsData(snap.val())
        })
    }

    const eventFieldErrorShow = () => {
        if(eventFieldError)
        return(
            <Alert severity="warning">Please Enter all Fields</Alert>
        )
    }

    const eventFieldSuccessShow = () => {
        if(eventSuccess)
        return(
            <Alert severity="success">Upload Evnt Success</Alert>
        )
    }


    useEffect(() => {
        getEventData()

        setTimeout(() => {
            if(eventSuccess)
                seteventSuccess(false)
            else if(eventError)
                seteventError(false)
            else if(eventFieldError)
                seteventFieldError(false)
        }, 3000);
    }, [eventSuccess,eventError,eventFieldError]);
    

    const eventImageUpload = (e) => {
        e.preventDefault();
        let file = eventPicture;
        var storage = firebase.storage();
        var storageRef = storage.ref();
        var uploadTask;

        uploadTask = storageRef.child(`events/image/${uuidv4()}/${file.name}`).put(file);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
                seteventProgress(true)
            },
            (error) =>{
                throw error
            },
            () =>{
            uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
                seteventPicURL(url)
            })
            seteventSuccess(true)
            seteventProgress(false)
        })
    }

    const eventPictureHandler = (e) => {
        seteventPicture(e.target.files[0]);
    }

    const eventSubmit = (e) => {
        e.preventDefault();
        const ID = uuidv4();
        seteventProgress(true)
        console.log(eventtitleMsg, eventPicURL,descriptionEvent,eventDate );
        if(eventtitleMsg === '' || eventPicURL === ''  || descriptionEvent === '' || eventDate === ''){
            seteventFieldError(true)
            seteventProgress(false)
        }
        else{
            const myRef = firebase.database().ref(`/events/${ID}`);
            myRef.set({
                name: eventtitleMsg,
                day: eventDate.getDay(),
                date: moment(eventDate).format("MMM Do YY"),
                month: eventDate.getMonth() + 1,
                year: eventDate.getFullYear(),
                end: eventEndDate,
                description: descriptionEvent,
                eventPic: eventPicURL
            }).then(res => {
                seteventtitleMsg('')
                seteventDate(new Date().getDate())
                setdescriptionEvent('')
                seteventPicURL('')
                seteventPicture('')
                seteventSuccess(true)
                seteventProgress(false)
            })
            .catch(er => {
                seteventError(true)
            })
        } 
    }

    const deleteEvents = (e,id) => {
        e.preventDefault();
        const myRef = firebase.database().ref(`/events/${id}`);
        myRef.remove();
        setsuccess(true)
    }

    // login and logout
    const [loginRedirect, setloginRedirect] = useState(false);
    const goLogin = () => {
        if(loginRedirect){
            return 
            <Redirect to={{ pathname: '/login'}}/>
        }   
    }

    const logoutHandler = (e) => {
        e.preventDefault();
        if(logout()){
            setloginRedirect(true)
        }
    }

    // enquries
    const [enquries, setenquries] = useState([]);
    const getAllEnquries = () => {
        var myQuery = firebase.database().ref(`/enqueries`);
        myQuery.on('value', snap => {
            if(snap.val()){
                setenquries(snap.val())
            }
        })
    }

    useEffect(() => {
        getAllEnquries()
    }, []);

    // Affiliation States
    const [affiliationName, setAffiliationName] = useState('');
    const [affiliationAddress, setAffiliationAddress] = useState('');
    const [affiliationPhoneNumber, setAffiliationPhoneNumber] = useState('');
    const [affiliationFax, setAffiliationFax] = useState('');
    const [affiliationEmail, setAffiliationEmail] = useState('');
    const [affiliationWebsite, setAffiliationWebsite] = useState('');
    const [affiliationState, setAffiliationState] = useState(''); // New State Field
    const [allAffiliationsData, setAllAffiliationsData] = useState([]);
    const [affiliationFieldError, setAffiliationFieldError] = useState(false);
    const [editMode, setEditMode] = useState(false); // Flag for edit mode
    const [editID, setEditID] = useState(''); // Stores the ID of the item being edited

    // Fetch affiliations from Firebase
    const getAffiliationData = () => {
        const myRef = firebase.database().ref(`/affiliations`);
        myRef.on('value', (snap) => {
            if (snap.val()) setAllAffiliationsData(snap.val());
        });
    };

    // Show error alert if required fields are missing
    const affiliationFieldErrorShow = () => {
        if (affiliationFieldError)
            return <Alert severity="warning">Please Enter all Fields</Alert>;
    };

    // Run on component mount
    useEffect(() => {
        getAffiliationData();
        setTimeout(() => {
            if (affiliationFieldError) setAffiliationFieldError(false);
        }, 3000);
    }, [affiliationFieldError]);

    // Handle form submission
    const affiliationSubmit = (e) => {
        e.preventDefault();

        if (
            affiliationName === '' ||
            affiliationAddress === '' ||
            affiliationPhoneNumber === '' ||
            affiliationFax === '' ||
            affiliationEmail === '' ||
            affiliationWebsite === '' ||
            affiliationState === '' // Check if state is empty
        ) {
            setAffiliationFieldError(true);
        } else {
            const myRef = firebase.database().ref(
                editMode ? `/affiliations/${editID}` : `/affiliations/${uuidv4()}`
            );

            myRef
                .set({
                    name: affiliationName,
                    address: affiliationAddress,
                    phoneNumber: affiliationPhoneNumber,
                    fax: affiliationFax,
                    email: affiliationEmail,
                    website: affiliationWebsite,
                    state: affiliationState, // Save state
                })
                .then(() => {
                    // Reset form fields
                    setAffiliationName('');
                    setAffiliationAddress('');
                    setAffiliationPhoneNumber('');
                    setAffiliationFax('');
                    setAffiliationEmail('');
                    setAffiliationWebsite('');
                    setAffiliationState(''); // Reset state

                    // Exit edit mode if applicable
                    setEditMode(false);
                    setEditID('');
                })
                .catch((error) => {
                    console.error('Error uploading affiliation:', error);
                });
        }
    };

    // Handle edit mode
    const editAffiliation = (id, data) => {
        setEditMode(true); // Enable edit mode
        setEditID(id); // Store the ID of the affiliation being edited

        // Populate the form fields with existing data
        setAffiliationName(data.name);
        setAffiliationAddress(data.address);
        setAffiliationPhoneNumber(data.phoneNumber);
        setAffiliationFax(data.fax);
        setAffiliationEmail(data.email);
        setAffiliationWebsite(data.website);
        setAffiliationState(data.state); // Populate state
    };

    // Handle deletion
    const deleteAffiliation = (e, id) => {
        e.preventDefault();
        const myRef = firebase.database().ref(`/affiliations/${id}`);
        myRef.remove();
    };

    return(
        <div style={{ padding:55, marginTop:100 }} >
        {goLogin()}
    
        <Button variant='outlined' style={{ marginBottom:45 }} onClick={logoutHandler} >
            Logout
        </Button>
    
        {successMesg()}
            
        <Box defaultValue={0} >
        <StyledTabsList style={{ background: "#26873e" }} value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} variant="scrollable" scrollButtons="auto">
            <StyledTab label="Facilities" className='tab--list--admin' />
            <StyledTab label="Events & News" />
            <StyledTab label="Testimonial" />
            <StyledTab label="Enquiries" />
            <StyledTab label="Affilation" />
        </StyledTabsList>

        <StyledTabPanel value={selectedTab} index={0}>
            <Container>
                <Row>
                    <Col sm={8}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={4}>
                            {imageUrls && Object.entries(imageUrls).map((P,index) => 
                                <Grid key={index} item>
                                    <LazyLoadImage height={200} width={200} src={`${P[1].url}?w=161&fit=crop&auto=format`} alt={P[1].category} /> 
                                    <Box>
                                        <Button variant='outline' onClick={(e) => deleteAdminPicures(e,P[0])} > Delete </Button>
                                    </Box>
                                </Grid>    
                            )}
                        </Grid>
                        </Grid>
                        </Grid>
                    </Col>
                    <Col sm={4}>
                    <div>
                        <p>Image uploader</p>
                        {!progressing ? (
                        <>
                            <Form.Group>
                                <Form.Control value={imageCategory} as="select" size="sm" onChange={(e) => setimageCategory(e.target.value)}>
                                <option>All</option>
                                <option>Rooms</option>
                                <option>Gym</option>
                                <option>Shuttle</option>
                                <option>Cards</option>
                                <option>Carrom</option>
                                <option>Food Menu</option>
                                <option>ZeroBar</option>
                                <option>Reading</option>
                                </Form.Control>
                            </Form.Group>
                            <div style={{ padding:20, marginTop:50, backgroundColor:'#296552', borderRadius:12 }}>
                                <label>
                                    Choose file
                                    <input type="file" id="file" onChange={adminImageHandle} />        
                                </label>
                                <button className="button" onClick={(e) => adminUpload(e,imageCategory)}>Upload</button>
                            </div>
                        </>
                        ) : (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        )}
                    </div>
                    </Col>
                </Row>
            </Container>
        </StyledTabPanel>
        
        
        <StyledTabPanel value={selectedTab} index={1}> 
            <Container>
            {eventFieldErrorShow()}
            {eventFieldSuccessShow()}
            <Row>
                <Col sm={8}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        {allEventsData && Object.entries(allEventsData).map((P,index) => {
                        return(
                            <Grid key={index} item >
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'}} 
                            style={{ backgroundColor: '#fff', borderRadius:12, borderWidth:8, border: '3px solid rgba(0, 0, 0, 0.05)' }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <LazyLoadImage height={100} width={100} src={P[1].eventPic} style={{ marginRight:32 }} />
                                    </ListItemAvatar>
                                    <ListItemText primary={P[1].name} style={{fontWeight:'800'}}
                                    secondary = {
                                        <React.Fragment>
                                            <Typography style={{ marginTop:50, fontWeight:'500' }} sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary"> 
                                                {P[1].description} 
                                            </Typography>
                                        </React.Fragment>
                                    }/>
                                </ListItem>
                                <Button onClick={(e) => deleteEvents(e,P[0])} variant="outlined" style={{ marginLeft:18, marginBottom:18, marginTop:15 }} >
                                    Delete
                                </Button>
                            </List>
                            </Grid>
                        )
                        })}
                    </Grid>
                    </Grid>
                    </Grid>
                </Col>
                <Col sm={4}>
                    <Container>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Enter the Event title</Form.Label>
                            <Form.Control type="text" placeholder="Event title" value={eventtitleMsg} onChange={(e) => seteventtitleMsg(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Enter the Event title</Form.Label>
                            <Form.Control type="text" placeholder="Event Description" value={descriptionEvent} onChange={(e) => setdescriptionEvent(e.target.value)} />
                        </Form.Group>

                        <Form.Label>Event Starting Date</Form.Label>
                        <DatePicker selected={eventDate} onChange={(date) => seteventDate(date)} dateFormat="MM/dd/yyyy" />
                        
                        {!eventProgress ? (
                        <>
                            <div style={{ marginTop:30, backgroundColor: '#CAD5E2', padding: 20, borderRadius: 12 }} >
                            <Form.Label>Choose file</Form.Label>                               
                            <input type="file" id="file" onChange={eventPictureHandler} />        
                            <Button variant="contained" className="button mt-4" onClick={eventImageUpload}>Upload</Button>
                            </div>
                            <Button variant='outlined' className="mt-4" onClick={eventSubmit}> Add Event </Button>
                        </>
                        ) : (
                            <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        )}
                    </Container>
                </Col>    
            </Row>    
            </Container>
        </StyledTabPanel>

        <StyledTabPanel value={selectedTab} index={2}>    
            <Container>
            <Row>
                <Col sm={8}>
                <div style={{ height: 400, width: '100%' }}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} style={{marginTop:50}}>
                    <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        {allTestimonialData && Object.entries(allTestimonialData).map((P,index) => {
                            return(
                            <Grid key={index} item style={{ margin:20 }} >
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} 
                            style={{ backgroundColor: '#26873E', padding:8, color:'white', borderRadius:8, shapeOutside: 12 }}
                            > 
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={P[1].profile} />
                                    </ListItemAvatar>
                                    <ListItemText primary={P[1].name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#fff" >
                                                {P[1].testimonial}
                                            </Typography>
                                        </React.Fragment>
                                    }/>
                                </ListItem>
                                <Button onClick={(e) => deleteTestimonial(e,P[0])} variant="contained" style={{ backgroundColor: 'white', color:'blue' }} >
                                Delete
                                </Button>
                            </List>
                            </Grid>
                            )
                        })}
                    </Grid>
                    </Grid>
                    </Grid>
                </div>
                </Col>
                
                <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter his Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={testimonyGiverName} onChange={(e) => settestimonyGiverName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter his Occupation</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={testimonyGiverOccupation} onChange={(e) => settestimonyGiverOccupation(e.target.value)} />
                    </Form.Group>

                    {!progressing ? (
                    <>
                        <label>
                            Choose Profile Picture
                        <input type="file" id="file" onChange={adminTestimonyImageHandle} />        
                        </label>
                        <Button variant='contained' className="button mt-4 mb-3" onClick={(e) => adminTestimonyUpload(e,imageCategory)}>Upload</Button>
                    </>
                    ) : (   
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    )}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter his Testimony</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={testimonyTest} onChange={(e) => settestimonyTest(e.target.value)} />
                    </Form.Group>
                    <Button variant="outlined" onClick={submitTestimonial}> Add </Button>
                </Col>
            </Row>
            </Container>
        </StyledTabPanel>

        <StyledTabPanel value={selectedTab} index={3}>
            <Container>
                <Row>
                <Col sm={10}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {enquries && Object.entries(enquries).map((p, index) => {
                        return(
                            <Grid item xs={4} style={{ backgroundColor: '#c7dbcc', borderRadius:12, padding:10, marginRight:8, marginBottom:10 }} >
                                <p> Name - {p[1].name} </p>
                                <p> Phone - {p[1].phone} </p>
                                <p> Occupation - {p[1].occupation} </p>
                            </Grid>
                        )
                    })}
                    </Grid>
                </Col>
                <Col sm={2}></Col>
                </Row>
            </Container>
        </StyledTabPanel>

        <StyledTabPanel value={selectedTab} index={4}>
            <Container>
            {affiliationFieldErrorShow()}
            <Row>
                <Col xs={12} sm={12} md={8}>
                    <Grid container spacing={2} className="affiliations-container">
                        {allAffiliationsData &&
                            Object.entries(allAffiliationsData).map(([id, data], index) => (
                                <Grid item style={{ width: '100%' }} key={id}>
                                    <List className="affiliation-box">
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={data.name}
                                                style={{ fontWeight: '800' }}
                                                secondary={
                                                    <React.Fragment>
                                                        <div>
                                                            <Typography style={{ fontWeight: '500' }} component="span" variant="body2" color="text.primary" >
                                                                Address: {data.address}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography style={{ fontWeight: '500' }} component="span" variant="body2" color="text.primary" >
                                                                Phone: {data.phoneNumber}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography style={{ fontWeight: '500' }} component="span" variant="body2" color="text.primary" >
                                                                Fax: {data.fax}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography style={{ fontWeight: '500' }} component="span" variant="body2" color="text.primary" >
                                                                Email: {data.email}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography style={{ fontWeight: '500' }} component="span" variant="body2" color="text.primary" >
                                                                Website: {data.website}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography style={{ fontWeight: '500' }} component="span" variant="body2" color="text.primary" >
                                                                State: {data.state}
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '10px', }} >
                                            <Button variant="contained" onClick={() => editAffiliation(id, data)} style={{ width: '150px' }} >
                                                Edit
                                            </Button>
                                            <Button variant="outlined" color="error" onClick={(e) => deleteAffiliation(e, id)} style={{ width: '150px' }} >
                                                Delete
                                            </Button>
                                        </div>
                                    </List>
                                </Grid>
                            ))}
                    </Grid>
                </Col>

                {/* Form for Adding or Editing */}
                <Col xs={12} sm={12} md={4}>
                    <Container>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationName">
                            <Form.Label>Affiliation Name</Form.Label>
                            <Form.Control type="text" placeholder="Affiliation Name" value={affiliationName} onChange={(e) => setAffiliationName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Address" value={affiliationAddress} onChange={(e) => setAffiliationAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationState">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" placeholder="State" value={affiliationState} onChange={(e) => setAffiliationState(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationPhoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Phone Number" value={affiliationPhoneNumber} onChange={(e) => setAffiliationPhoneNumber(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationFax">
                            <Form.Label>Fax</Form.Label>
                            <Form.Control type="text" placeholder="Fax" value={affiliationFax} onChange={(e) => setAffiliationFax(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" value={affiliationEmail} onChange={(e) => setAffiliationEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAffiliationWebsite">
                            <Form.Label>Website</Form.Label>
                            <Form.Control type="text" placeholder="Website" value={affiliationWebsite} onChange={(e) => setAffiliationWebsite(e.target.value)} />
                        </Form.Group>
                        <Button variant="contained" type="submit" onClick={affiliationSubmit} style={{ width: '100%' }} >
                            {editMode ? 'Update Affiliation' : 'Add Affiliation'}
                        </Button>
                    </Container>
                </Col>
            </Row>
        </Container>
        </StyledTabPanel>

    </Box>
    </div>       
    )
}

export default Admin;