import React, {useState} from "react";
import {Container} from "react-bootstrap";
import "./Prominent.css";
import { Fade, Bounce, Reveal } from "react-reveal";
import { Carousel } from "react-bootstrap";
import ca1 from "../../assets/images/ent/cards.png";
import tw from "twin.macro";
import csclogo from "../../assets/images/csclogo.png"


const Column = tw.div``;
const ImageColumn = tw(Column)`  relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image1 = tw.img`max-w-full w-80 sm:rounded relative p-4`;


function Prominent() {
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
    return(
        <Container className="main--cont--prominent">
            <div >
            <Fade bottom>
            <div className="main--title--prominent">Some of our</div>
            <div className="main--sub--prominent"><p className="crossline--prominent"><span className="hsspan">PROMINENT MEMBERS</span></p></div>
            
            </Fade>
            </div>
           
            <Carousel activeIndex={index} onSelect={handleSelect} className="main--carousel--prom" style={{background:"#26873e", color:"#26873e", borderRadius:"20px", overflow:"hidden"}} indicators={false} prevIcon={false} nextIcon={false}>
                
                <Carousel.Item className="carousel--item--pro" interval={4000} >
                    
                    <div className="carousel--overlay">
                    <ImageColumn>
                        <ImageContainer>
                            <Image1 src={csclogo} />
                        </ImageContainer>
                    </ImageColumn>
                    </div>
                    <div className="carousel--content">
                        
                    <div className="carousel--content--title">Shri.K.P.RAMASAMY, Chairman, KPR Group of Companies</div>
                        <div className="carousel--content--sub">KPR Educational Institutions, He is our member. Though he is a corporate member of the 
                        cosmopolitan club (English Club), R.S.Puram Club and others, he is a daily. visitor to our club for
                        his sporting activities. He was conferred with Doctorate by Kalinga university recently.
                        </div>
                        
                    </div>
                    
  
                </Carousel.Item>

                <Carousel.Item className="carousel--item--pro" interval={4000}>
                    <div className="carousel--overlay">
                        <ImageColumn>
                            <ImageContainer>
                                <Image1 src={csclogo} />
                            </ImageContainer>
                        </ImageColumn>
                    </div>
                    <div className="carousel--content">
                        
                    <div className="carousel--content--title">Padmashree Shri. MURUGANANDAM</div>
                        <div className="carousel--content--sub">He has participated in the panel discussion held at Seattle under the banner of 
                            Key Stone Symposia-to discuss about “How innovation can be used to solve global 
                            health and development and development challenges” along with
                            Bill Gates. He was recognised as one of the top-most
                        </div>
                    </div>
                </Carousel.Item >

                <Carousel.Item className="carousel--item--pro" interval={4000}>
                <div className="carousel--overlay">
                    <ImageColumn>
                        <ImageContainer>
                            <Image1 src={csclogo} />
                        </ImageContainer>
                    </ImageColumn>
                    </div>
                    <div className="carousel--content">
                        
                    <div className="carousel--content--title"> Dr.J.G. SHANMUGANATHAN, Chairman of Ganga Hospital,</div>
                        <div className="carousel--content--sub">
                            100 eminent people in the world by Times Magazine. He is also a member of our club.
                            He is honoured with PADMASHREE AWARD during the year 2016. He was our former Vice President 
                            
                        </div>
                       
                    </div>
                </Carousel.Item>
                </Carousel>
            
        </Container>
    )
}


export default Prominent;



