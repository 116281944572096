import React from "react";
import "./Footer.css";
import styled from "styled-components";
import tw from "twin.macro";
import csc from "../assets/images/cscnav.png";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

// Basic column structure with responsive layouts
const Column = tw.div ``;
const ImageColumn = tw(Column)`
  lg:mr-0 relative z-0 justify-center flex flex-col items-center`;  

const ImageContainer = tw.div `relative z-0 mb-4`; 

const Row = tw.div `
  flex flex-col lg:flex-row justify-between items-center max-w-screen-2xl mx-auto sm:px-8`;

const TextColumn = tw(Column)`
  lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl justify-center text-center lg:text-left p-4`;

const NavLink = tw.a `
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 text-black
  font-sans tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent  no-underline`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-32 mt-2 z-10`} 
    ${tw`sm:w-40 md:w-40 lg:w-32`} 
  }
`;

const FooterContainer = styled.div`
  ${tw`w-full`};

  /* Mobile: Horizontal lines with margin for better visibility */
  @media (max-width: 1024px) {
    .footer-column {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px; /* Add margin to separate the sections */
      padding-bottom: 20px; /* Add some padding at the bottom for spacing */
    }
  }

    /* Mobile view adjustments */
  @media (max-width: 1024px) {
    .footer--address {
      font-size: var(--step--1); /* Larger size for mobile view */
    }
    .footer--mail {
      font-size: var(--step--1); /* Larger size for mobile view */
    }
  }

  /* Laptop and larger: Vertical lines */
  @media (min-width: 1025px) {
    .footer-column {
      border-right: 1px solid #ccc;
    }
  }
`;




function Footer() {
  return (
    <FooterContainer>
      <div className="footer--main--cont">
        <Container style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Row>
            {/* Logo Column */}
            <ImageColumn className="footer-column"
              style={{ padding: "0px 20px 20px 0px " }}>
              <ImageContainer>
                <LogoLink>
                  <img src={csc} alt="CSC Logo" />
                </LogoLink>
              </ImageContainer>
              <h4 style={{ color: "#FFF" }}>
                  <strong>The Coimbatore Social Club</strong>
              </h4>
            </ImageColumn>

            {/* Contact Information Column */}
            <TextColumn className="footer-column"
              style={{ padding: "20px" }}>
              <h4 style={{ color: "#FFF" }}>
                  <strong>Contact Us</strong>
              </h4>
              <div className="footer--mail">Reg no. 46 of 1955</div>
              <div className="footer--mail">
                440, Thadagam Main Rd, R.S. Puram,<br />
                Coimbatore, Tamil Nadu 641002
              </div>
              <div className="footer--mail">
                Email: thecsc1965@gmail.com, thecsckovai@gmail.com
                <br />
                Ph: 0422- 2541588, Cell: 98941 13037
              </div>
            </TextColumn>

            {/* Follow Us Column */}
            <Column style={{ padding: "0 20px" }}>
              <h4 style={{ color: "#FFF" }}>Follow Us</h4>
              <p className="social-container" style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                <a
                  href="https://youtube.com"
                  className="youtube social"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#FFF" }}
                >
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
                <a
                  href="https://facebook.com"
                  className="facebook social"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#FFF" }}
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="https://instagram.com"
                  className="instagram social"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#FFF" }}
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </p>
            </Column>
          </Row>
        </Container>
      </div>
      <div className="thedot--cont">
        <Container className="thedot--cont--child">
          <div className="thedot--text2">CSC &copy; all rights reserved 2025.</div>
          <div className="thedot--text">
            powered by <span style={{ cursor: "pointer" }} onClick={() => window.open("https://www.activetheory.org", "_blank")}>Active Theory</span>
          </div>
        </Container>
      </div>
    </FooterContainer>
  );
}

export default Footer;
