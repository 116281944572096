import React, {useState} from "react";
import { Container } from "react-bootstrap";
import "./HomeSect.css";
import { Fade } from "react-reveal";
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import modalbg from "../../assets/images/modalbg.png";
import tw from "twin.macro";
import { Form } from 'react-bootstrap';
import { TextField } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import firebase from "firebase";
import { v4 as uuidv4 } from 'uuid';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };
  const OpacityOverlay = tw.div`  z-10 absolute inset-0 bg-black opacity-25 rounded-lg`;

function HomeSect(){
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [occupation, setoccupation] = useState('Business');

    const submitEnquery = (e) => {
        e.preventDefault();
        var id = uuidv4();
        var myRef = firebase.database().ref(`/enqueries/${id}`);
        myRef.set({
            name: name,
            phone: phone,
            occupation: occupation
        }).then(res => {
            setname('')
            setphone('')
            setoccupation('')
            handleClose()
        })
        .catch(er => {

        })
          }

    return(
        <div className="main--cont--homesect">
            
            <Container >
                <div className="cont--in--homesect">
                    
                    <div className="cont--in--homesectcont">
                    <Fade bottom>
                    <div className="main--title--homesect">MEMBERSHIP</div>
                    <div className="main--sub--homesect"><p className="crossline--homesect"><span className="hsspan">APPLY TODAY</span></p></div>
                    <div className="main--para--homesect">
                    If you would like to register your interest in <br /> Membership to CSC, please click below
                    </div>
                    <div className="homesect--btn--container"><button className="homesect--btn" onClick={handleOpen}>FIND OUT MORE</button></div>
                    <Modal
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"
                        open={open}
                        
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                        <Box sx={style} className="modal--membership" >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1 },
                            }}
                           
                            noValidate
                            autoComplete="off"
                            >
                            
                            <div className="modal--cont--homesect">
                            <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            onChange={(e) => setname(e.target.value)}
                            className="text--field--homesect"
                            />
                            <TextField
                            required
                            id="outlined-required"
                            label="phone no."
                            onChange={(e) => setphone(e.target.value)}
                            className="text--field--homesect"
                            />
                            
                            <button type="submit" className="submit--btn--homesect" onClick={submitEnquery}>CONFIRM</button>
                            <button className="cancel--btn--homesect">CANCEL</button>
                            
                            
                            </div>
                            </Box>
                        </Box>
                        </Fade>
                    </Modal>
  
                    </Fade>
                    
                    </div>
                    
                </div>
            </Container>
            
        </div>
    )
}

export default HomeSect;