import React from "react";
import { Container } from "react-bootstrap";
import "./FanddSection.css";
import tw from "twin.macro";
import {Fade} from "react-reveal";
import styled from "styled-components";
import { css } from "styled-components/macro";
import bfast from "../../assets/images/food/7.png";
import lunch from "../../assets/images/food/4.png";
import dinner from "../../assets/images/food/3.png";
import wine from "../../assets/images/food/wine.png";
import beer from "../../assets/images/food/beer.png";
import hd from "../../assets/images/food/hd.png";

const SectionHeading = tw.h2` pt-0   tracking-wide text-center`;
const Posts = tw.div`mt-12 flex flex-wrap -ml-0 relative`;
const Post = tw.a`flex flex-col h-full bg-white rounded no-underline`;
const PostImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;
const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.p`transition duration-300 `;
const PostContainer = styled.div`
  ${tw`relative z-20 mt-0 sm:pt-3 pr-0 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw``}
    }
 
    
  `}
`;

const Heading = tw(SectionHeading)`xl:text-center lg:text-center sm:text-center leading-snug  mt-0 mb-0`;

function FanddSection() {
    return(
        <Container >
            <div className="sub--cont--food">
                <Fade bottom>
                    <Heading><div className="main--title--food">FOOD & BEVERAGES</div></Heading>
                    <div className="main--sub--food"><p className="crossline--indulge"><span className="inspan">INDULGE & DEVOUR</span></p></div>
                    <div className="main--para--food">
                        A team of chefs, sommeliers and mixologists have been
                        assembled to design an outstanding array of menus, cocktail and 
                        wine lists for The Club’s Members and their guests to choose from. Expertly sourced,
                        seasonal ingredients, wines and spirits are brought together using 
                        traditional and subversive techniques to create truly unique dining and drinking experiences.
                    </div>
                    <div className="food--btn--container"><button className="food--btn">DISCOVER MORE</button></div>
                </Fade>
            </div>


            <div className="menu--cards--sect">
                <Fade bottom>
                <div className="main--sub--food"><p className="crossline--indulge"><span className="inspan">INDULGE & DEVOUR</span></p></div>
                <div className="main--cards--title">EXPERIENCE A SELECTION OF THE CLUB’S SAMPLE MENUS BELOW.</div>
                </Fade>
                <Posts>
                    <PostContainer >
                    <Post >
                        <PostImage imageSrc={bfast} />
                        <PostText>
                        <PostTitle className="food--card--title">BREAKFAST MENU</PostTitle>
                        
                        </PostText>
                    </Post>
                    </PostContainer>
                    <PostContainer>
                    <Post >
                        <PostImage imageSrc={lunch} />
                        <PostText>
                        <PostTitle className="food--card--title">LUNCH MENU</PostTitle>
                        
                        </PostText>
                    </Post>
                    </PostContainer>
                    <PostContainer>
                    <Post >
                        <PostImage imageSrc={dinner} />
                        <PostText>
                        <PostTitle className="food--card--title">DINNER MENU</PostTitle>
                        
                        </PostText>
                    </Post>
                    </PostContainer>
                    <PostContainer>
                    <Post >
                        <PostImage imageSrc={wine} />
                        <PostText>
                        <PostTitle className="food--card--title">WINE</PostTitle>
                        
                        </PostText>
                    </Post>
                    </PostContainer>
                    <PostContainer>
                    <Post >
                        <PostImage imageSrc={beer} />
                        <PostText>
                        <PostTitle className="food--card--title">BEER MENU</PostTitle>
                        
                        </PostText>
                    </Post>
                    </PostContainer>
                    <PostContainer>
                    <Post >
                        <PostImage imageSrc={hd} />
                        <PostText>
                        <PostTitle className="food--card--title">HOT DRINK MENU</PostTitle>
                        
                        </PostText>
                    </Post>

                    </PostContainer>
                </Posts>
            </div>

                
        </Container>
    )
}

export default FanddSection;