import React from "react";
import "./Threesixty.css";
import Slider from "@mui/material/Slider";
import { Container } from "react-bootstrap";
import { Pannellum, PannellumVideo } from "pannellum-react";
import pano from "../../assets/images/three/pano.jpg";


export default function Threesixty() {
    
    return(
        <div className="main--cont--three">
            <Container>
            <Pannellum
                className="pannel"
                height= "450px"
                position= "absolute"
                display="flex"
                image={pano}
                pitch={10}
                yaw={180}
                hfov={110}
                autoLoad
                onLoad={() => {
                    console.log("panorama loaded");
                }}
            />
                      
            </Container>
        </div>
    ) 
}

