import React, {useEffect, useState} from 'react';
import { login } from '../PrivateRoutes/admin_helper';
import {Form, Button} from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const Login = () => {

    const [email, setemail] = useState('');
    const [passcode, setpasscode] = useState('');

    const [isRedirect, setisRedirect] = useState(false);

    const loginHandler = (e) => {
        e.preventDefault();
        console.log(login({email,passcode}));
        if(login({email,passcode})){
            setisRedirect(true)
        }
        else{
            setadminerror(true)
        }
    }

    const [adminerror, setadminerror] = useState(false);

    const shoeAdminerror = () => {
        if(adminerror)
            return(
                <Alert severity="error">Enter correct email or password</Alert>
            )
    }
    
    useEffect(() => {
      if(adminerror)
        setTimeout(() => {
            setadminerror(false)
            setemail('')
            setpasscode('')
        }, 2000);
    }, [adminerror]);
    

    const goAdmin = () => {
        if(isRedirect)
            return <Redirect to={{
                pathname: '/csc/admin'}} />
    }

    return(
        <div
        style={{
            padding:50,
            marginTop:100
        }}
        >{goAdmin()}
        {shoeAdminerror()}
            <Form>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setemail(e.target.value)}/>
    
  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Password</Form.Label>
    <Form.Control type="password" placeholder="Password" value={passcode} onChange={(e) => setpasscode(e.target.value)}/>
  </Form.Group>

  <Button variant="primary" type="submit" onClick={loginHandler}>
    Submit
  </Button>
</Form>
        </div>
    )
}

export default Login;