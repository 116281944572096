import React from "react";
import "./EntSection.css";
import { Container } from "react-bootstrap";
import tw from "twin.macro";
import carrom from "../../assets/images/ent/carrom.png";
import cards from "../../assets/images/ent/cards.png";
import shuttle from "../../assets/images/ent/shuttle.png";
import gym from "../../assets/images/ent/gym.png";
import reading from "../../assets/images/ent/reading.jpeg"
import corridor from "../../assets/images/ent/corridor.jpg"
import { Fade } from "react-reveal"
import { Link } from "react-router-dom";




const SectionHeading = tw.h2` pt-4   tracking-wide text-center`
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)` lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl justify-center`;
const Heading = tw(SectionHeading)`xl:text-center lg:text-center sm:text-center leading-snug  mt-2 mb-10`;
const ImageColumn = tw(Column)` lg:mr-0 relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image1 = tw.img`max-w-full w-96 sm:rounded relative p-4`;


function EntSection() {
    return(
    <Container className="main--cont--carrom">
        
          <Row className="row--ent">
          <ImageColumn  className="res--bottom--ent">
              <ImageContainer>
                <Image1 src={carrom}  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">CARROM ROOM</span></p></Heading>
              <div>
                    <p className="main--para--carrom">The	Coimbatore	Social	Club	has	a	spacious and	the	state	of	the	art	"Carrom	room"	for	the	recreation	of	the	members
	,	tournaments	are	conducted periodically.</p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/carrom" style={{textDecoration:"none"}}> <button className="entertain--btn" >DISCOVER MORE</button></Link></div>
              </div>
              </Fade>
            </TextColumn>
           
          </Row>
          <Row className="row--ent">
          
            <TextColumn>
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">CARDS ROOM</span></p></Heading>
              <div>
                    <p className="main--para--carrom">The	Coimbatore	Social	Club	has	air-conditioned	and	spacious	the	state	of	the	art	"Cards	room"	for	the	recreation	of	the	members.
Various	indoors	games	such	as	carrom,	chess,	Table	Tennis	etc	are	played	and	tournaments	are	conducted periodically.</p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/cardsroom" style={{textDecoration:"none"}}><button className="entertain--btn">DISCOVER MORE</button></Link></div>
              
              </div>
              </Fade>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image1 src={cards}  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
          </Row>
          <Row className="row--ent">
          <ImageColumn className="res--bottom--ent">
              <ImageContainer>
                <Image1 src={shuttle}  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">SHUTTLE AREA</span></p></Heading>
              <div>
                    <p className="main--para--carrom">The	Coimbatore Social Club	has	an
                    well	maintained	indoor	Shuttle
                    badminton	court	with	synthetic	floor.</p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/shuttle" style={{textDecoration:"none"}}><button className="entertain--btn">DISCOVER MORE</button></Link></div>    
              </div>
              </Fade>
            </TextColumn>
          </Row>

          <Row className="row--ent">
            <TextColumn>
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">GYM AREA</span></p></Heading>
              <div>
                    <p className="main--para--carrom">	The	Coimbatore Social Club	is	equipped	with	the	best	equipment	in	Positive	Lifestyle	Systems	is	a	
truly	this	arena.	responsible	&	effective.	The	club	offers	this	service	with	confidence.	</p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/gym" style={{textDecoration:"none"}}><button className="entertain--btn">DISCOVER MORE</button></Link></div>
              
              </div>
              </Fade>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image1 src={gym}  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
          </Row>
          
          <Row className="row--ent"> 
            <ImageColumn className="res--bottom--ent">
              <ImageContainer>
                <Image1 src="https://images.unsplash.com/photo-1611251135345-18c56206b863?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">TABLE TENNIS</span></p></Heading>
              <div>
                    <p className="main--para--carrom">The	Coimbatore	Social	Club	Various	indoors	games	such	as	carrom,	chess,	Table	Tennis	etc	are	played	and	tournaments	are	conducted.	</p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/shuttle" style={{textDecoration:"none"}}><button className="entertain--btn">DISCOVER MORE</button></Link></div>
              
              </div>
              </Fade>
            </TextColumn>
          </Row>

          <Row className="row--ent" style={{ marginBottom: "70px"}}>
            <TextColumn>
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">READING ROOM</span></p></Heading>
              <div>
                    <p className="main--para--carrom">	The Coimbatore Social Club offers a peaceful Reading Room with a variety of books,
                       magazines, and newspapers, perfect for relaxation and learning.</p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/read" style={{textDecoration:"none"}}><button className="entertain--btn">DISCOVER MORE</button></Link></div>
              </div>
              </Fade>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image1 src={reading}  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
          </Row>
          
          {/* <Row className="row--ent">
            <ImageColumn className="res--bottom--ent">
              <ImageContainer>
                <Image1 src={corridor}  style={{width:"520px"}} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn className="res--top--ent">
            <Fade bottom>
              <Heading className="main--title--carrom"><p className="cross--line"><span className="cspan">CORRIDOR AREA</span></p></Heading>
              <div>
                    <p className="main--para--carrom">The Coimbatore Social Club’s Corridor Area is a thoughtfully designed space connecting recreational zones, 
                      ideal for relaxation and conversations.
                    </p>
              </div>
              <div className="main--button">
                  <div className="ent--btn--container"><Link to="/corridor" style={{textDecoration:"none"}}><button className="entertain--btn">DISCOVER MORE</button></Link></div>
              </div>
              </Fade>
            </TextColumn>
          </Row> */}
      </Container>
    )
}

export default EntSection;