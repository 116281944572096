import React from "react";
import Follow from "../footer/Follow";
import Footer from "../footer/Footer";
import EventsMain from "./eventsComp/EventsMain";
import EventsSect from "./eventsComp/EventsSect";


function Events() {
    return(
    <>
    <EventsMain />
    <EventsSect />
    <Follow />
    <Footer />
    </>
        
    )
}

export default Events;