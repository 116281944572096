import React, {useState} from "react";
import EventsSect from "../events/eventsComp/EventsSect";
import BgAsImage from "../foodanddrink/Foodanddrinkcomp/BgAsImage";
import Follow from "../footer/Follow";
import Footer from "../footer/Footer";
import { imageRenderer } from "../image_api/image_render";
import NewNavbar from "../navbar/Navbar";
import AboutComp from "./homeSections/AboutComp";
import HomeMain from "./homeSections/HomeMain";
import HomeSect from "./homeSections/HomeSect";
import Prominent from "./homeSections/Prominent";
import Society from "./homeSections/Society";
import Testimonial from "./homeSections/Testimonial";
import Threesixty from "./homeSections/Threesixty";

function Home() {
    return(
        <div>
            <HomeMain />
            <AboutComp/>

            <Prominent />
            {/* <EventsSect/> */}
            <Testimonial />

            <HomeSect />    
            <BgAsImage />
            
            <Follow />
            <Footer />
        </div>
    )
}

export default Home; 